@import './components';

@font-face {
  font-family: 'MyWebFontCenturyGothic';
  src: url('../assets/fonts/CenturyGothicRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  @include background-image('../../assets/background.jpg');
  margin: 0;
  padding: 0;
  overflow: auto;
  font-family: 'MyWebFontCenturyGothic';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    @include size(100vw, 100vh);
    overflow: auto;
    font-size: calc(7px + 0.4vw);
    color: #D8D9D9;
    user-select: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}