@mixin size($width: auto, $height: auto) {
    width: $width;
    height: $height;
}

@mixin flex($justify: center, $align:center) {
    display: flex;
    justify-content: safe $justify;
    align-items: safe $align;
}

@mixin btn($width:fit-content, $cursor: pointer) {
    @include flex();
    width: $width;
    cursor: $cursor;
    background-color: var(--clr-default-50);
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #fff;
}

@mixin gradient-border-box {
    border: solid 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #212133, #181546), linear-gradient(to right, #AAAAA9, #F0F1F1);
    background-origin: border-box;
    background-clip: content-box, border-box;
}

@mixin wrap-word {
    white-space: pre-wrap;       
    word-wrap: break-word;       
    word-break: break-all;
    white-space: normal;
}

@mixin text-fadeIn($time: 2s) {
    animation: fadein $time;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

@mixin scrollbar {
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px #0000004d;
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-image: linear-gradient(0deg, var(--clr-scrollbar-10) 20%, var(--clr-scrollbar-20) 50%, var(--clr-scrollbar-30) 80%);
    }
}

@mixin background-image($path, $size:cover) {
    background-image: url($path);
    background-position: center;
    background-repeat: no-repeat;
    background-size: $size;
}