@import '../abstracts';

.login {
    @include size(100vw, 100vh);
    @include flex();
    @include scrollbar();

    @media screen and (max-width: 300px) {
        justify-content: safe flex-start;
    }

    @media screen and (max-height: 255px) {
        align-items: safe flex-start;
    }

    &__border {
        @include gradient-border-box();
        width: 35vw;
        max-width: 550px;
        min-width: 300px;

        &__position {
            @include flex();
            flex-direction: column;
            margin: 50px;
        }
    }

    &__logo {
        @include size(20em, 5em);
        @include background-image('../../assets/ICS-security_logo.png', contain);
    }

    &__title {
        @include flex();
        margin: 5% 0 5% 0;
        color: var(--clr-default-50);
        font-size: 2em;
        text-align: center;
    }

    &__form {
        @include size(100%);
        display: flex;
        flex-direction: column;
        font-size: 1em;
    }

    &__input {
        padding: 0.5em;
    }

    &__error__message {
        @include text-fadeIn();
        color: var(--clr-default-90);
        text-align: center;
        margin-bottom: 5px;
    }

    &__submit {
        @include btn();
        width: 50%;
        margin: auto;
    }

    &__input, &__submit {
        border-radius: 5px;
        outline: none;
        border: 1px solid #000;
        font-size: 1em;
        margin-bottom: 5%;
    }
}